import React from 'react';

export const errorMessages = {
  required: 'This field is required',
  emailPattern: 'Invalid email address',
  minLength: (minLength) => `This field must have at least ${minLength} characters`,
  maxLength: (maxLength) => `This field can have a maximum of ${maxLength} characters`,
  minNumber: (minNumber) => `This field must be greater than ${minNumber}`,
};

export const Error = ({ children }) => <small className="d-block form-text text-danger mt-2">{children}</small>;
