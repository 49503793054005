import React from 'react';
import * as styles from './Faq.module.scss';
import './Faq.css';
import SectionTitle from '../SectionTitle/SectionTitle';
import { Accordion } from 'react-bootstrap';

const Faq = () => {
  return (
    <section className={`${styles.faq} faq`}>
      <div className={styles.wrapper}>
        <SectionTitle coloredText="FAQ" subtitle="Frequently Asked Questions" />

        <div className={styles.accordion_wrapper}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header as="h3">How much does it cost to create a website?</Accordion.Header>
              <Accordion.Body>
                The cost of creating a website depends on its complexity and the features you want to implement. A
                simple One-Page website starts at 700 PLN, while more complex sites or e-commerce stores may start from
                1600 PLN. I also offer custom pricing based on the specific requirements of your project.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header as="h3">How long does it take to create a website?</Accordion.Header>
              <Accordion.Body>
                The time required depends on the project's complexity. A simple One-Page site can be ready within a few
                days, while more complex projects like Multi-Page sites or e-commerce stores may take 2 to 4 weeks. For
                complex projects, this timeframe may extend depending on your needs and content availability.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header as="h3">What is needed to start working on the website?</Accordion.Header>
              <Accordion.Body>
                To get started, I need basic information about your business, your vision for the website, and any
                materials you’d like to include (texts, images, logos, etc.). If you don’t have ready materials, I can
                assist in preparing them.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header as="h3">Can I manage the website myself after launch?</Accordion.Header>
              <Accordion.Body>
                Yes! I offer websites based on intuitive content management systems (CMS), such as WordPress. You will
                receive access to an admin panel, allowing you to update content, add images, and manage the website
                without any coding knowledge.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header as="h3">Do you provide technical support after the website launch?</Accordion.Header>
              <Accordion.Body>
                Yes, I offer 3 months of technical support after launch, covering minor updates and help with site
                management. After this period, you can opt for additional technical support services if needed.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header as="h3">Can I commission a logo design along with the website?</Accordion.Header>
              <Accordion.Body>
                Yes! I also offer professional logo design services. I create unique and modern logos that perfectly fit
                your company’s image. You can combine the logo design with the website creation in one package.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header as="h3">Are the websites you create responsive?</Accordion.Header>
              <Accordion.Body>
                Yes, all the websites I create are fully responsive. This means your site will look great and work
                smoothly on desktops, tablets, and smartphones.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header as="h3">What technologies do you use to build websites?</Accordion.Header>
              <Accordion.Body>
                I build websites using the latest technologies, such as HTML5, CSS3, JavaScript, and popular CMS
                platforms like WordPress. This ensures your website is modern, efficient, and SEO-optimized.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
