// extracted by mini-css-extract-plugin
export var button = "Contact-module--button--8012b";
export var contact = "Contact-module--contact--f0b32";
export var dark = "Contact-module--dark--53df2";
export var form = "Contact-module--form--a408a";
export var form_group = "Contact-module--form_group--17e2d";
export var form_wrapper = "Contact-module--form_wrapper--8a578";
export var info = "Contact-module--info--dd21a";
export var info_item = "Contact-module--info_item--7896c";
export var info_wrapper = "Contact-module--info_wrapper--82907";
export var wrapper = "Contact-module--wrapper--a8958";